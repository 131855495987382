<template>
  <b-container>
    <b-row v-if="loading">
      <SplashScreen />
    </b-row>
    <div v-else>
      <b-row>
        <NavBar />
      </b-row>
      <b-row>
        <router-view />
      </b-row>
    </div>
  </b-container>
</template>  

<script>
import NavBar from "@/components/Navigationbar.vue";
import SplashScreen from "@/components/Splash.vue";

export default {
  name: "ParnikaApp",
  components: {
    NavBar,
    SplashScreen,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    setTimeout(() => {
      this.loading = false;
    },5000);
    console.log(this.loading);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  line-height: 1.5;
  font-size: 15px;
  color: #333;
}
</style>

<template>
  <div class="mt-5">
    <b-row>
      <span class="text-center"><h4 class="display-5">Doctors Info</h4></span>
    </b-row>
    <b-row class="justify-content-center mb-5">
      <b-col sm="3">
        <b-card
          title="Dr.Snehali Gaonkar"
          :img-src="require('../assets/snehali.jpeg')"
          img-alt="Image"
          img-top
          tag="article"
          class="p-2 mt-2 shadow"
        >
          <b-card-text>
            <p>M.D(Panchakarma), CYS, YPV</p>
            <p>
              <b-button v-b-modal.snehali variant="outline-success"
                >View Full Profile
                <b-icon
                  icon="arrow-right-square-fill"
                  variant="warning"
                ></b-icon
              ></b-button>
            </p>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col sm="3">
        <b-card
          title="Dr. Laxmi Bidari"
          :img-src="require('../assets/LaxmiB.jpeg')"
          img-alt="Image"
          img-top
          tag="article"
          class="p-2 mt-2 shadow"
        >
          <b-card-text>
            <p>BAMS, M.D</p>
            <p>
              <b-button v-b-modal.laxmi variant="outline-success"
                >View Full Profile
                <b-icon
                  icon="arrow-right-square-fill"
                  variant="warning"
                ></b-icon
              ></b-button>
            </p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="snehali" size="lg" title="Dr. Snehali Gaonkar ">
      <b-row class="p-2">
        <ul>
          <b>Certified with</b>
          <li>B.A.M.S, M.D (Panchakarma) from RGUHS Bangalore</li>
          <li>
            Certified with Ayurvedic Dermatology, Ayurvedic Trichology,
            Ayurvedic Cosmetology from Dr. Shivananad Educational Institute and
            Medical sciences PVT.LTD
          </li>
          <li>Yoga Prana Vidya (YPV) from The Inner Sciences India Trust</li>
          <li>
            Certificate Course in Yoga Studies (C.Y.S.) from Karnataka
            University
          </li>
        </ul>
      </b-row>
      <b-row class="p-2">
        <ul>
          <b>Achivements</b>
          <li>
            Secured overall 10th rank, 5th rank in Sharira Kriya, 4th rank in
            Dravyaguna, 9th rank in Charakasamhita in R.G.U.H.S examination
          </li>
          <li>Published articles in national and international journals</li>
          <li>
            Written the book on panchakarma named as "Panchakarma Pradeepika (A
            comprehensive notes on panchakarma tattva)
          </li>
        </ul>

        <ul>
          <b>Professional skills</b>
          <li>
            Consulting and providing adequate guidance to patients (treatments,
            yoga, mudra, pathya etc.)
          </li>
          <li>Keeping track and record of the health of the patients</li>
          <li>
            Knowledge of multiple angles of ayurveda especially panchakarma
            which is actively used in the different areas, such as internal
            medicine, surgery, psychiatry, ENT, gynaecology, sexual vitality,
            toxicology, obstetrics and paediatrics etc
          </li>
          <li>
            Performing specialised panchakarma procedures like Vamana,
            virechana, basti, nasya, raktamokshana etc
          </li>
          <li>Art of healing by Yoga Prana Vidya and meditation</li>
          <li>
            Has sound communication skill and able to explain the treatment plan
            to a patient
          </li>
        </ul>
      </b-row>
    </b-modal>

    <b-modal id="laxmi" size="lg" title="Dr. Laxmi Bidari">
      <b-row class="p-2">
        <p>
          Speality in Infertility Pcod Thyroid diet plan according to ayurveda
          and Tridosha siddanth pregnancy yoga and Garbhasansakara
        </p>
        <ul>
          <b>Certified with</b>
          <li>
            Arogyam Institute of Ayurveda Nutrition and yoga (internationally
            recognized) under N.S.D.C and Minister of Skill India Development
            and Entrepreneurship
          </li>
          <li>
            Pregnancy yoga and Garbhasansakar under Dr Malati's Ayur yoga school
            affliated with Ministry of Ayush Government of India
          </li>
          <li>
            Infertility and Gynecological Disorders from Sushruta Academy pune
          </li>
        </ul>
      </b-row>
      <b-row class="p-2">
        <ul>
          <b>Achivements</b>
          <li>
            3rd place in Best paper award in National seminar of
            pharmacovigilance held at Manipal Institute udupi Received Best
            paper in research work in folklore drug Aglaia elaeagnoidea by
            Journal of Ayurveda medical science
          </li>
          <li>Written articles in National and international journals</li>
          <li>And also writing regular articles in shravana platform</li>
        </ul>

        <ul>
          <b>Professional skills</b>
          <li>
            Consulting and providing guidance in Infertility Pcod Thyroid diet
            plan according to ayurveda and Tridosha siddanth Preconception
            guidance according , panchakarma diet and yoga
          </li>
          <li>Pregnancy yoga and Garbhasansakar according to monthly regime</li>
          <li>Punsavan karma for healthy baby</li>
          <li>
            Coustmised diet plan for individual according to various diseases
          </li>
        </ul>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "DoctorsInfo",
};
</script>

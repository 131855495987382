<template>
  <b-row align-v="center" class="justify-content-center outer">
    <b-col sm="6" align-h="center" class="d-flex justify-content-center">
      <div class="ring">
      </div>
      <img :src="require('../assets/logo.png')" alt="Img" class="logo" />
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "SplashScreen",
};
</script>
<style scoped>

.logo {
    width: 250px;
    
    height: 250px;
}
.outer{
    height:90vh;
    background-color: white;
}

.ring {
    position: absolute;
    width: 250px;
    height: 250px;
    border: 14px solid transparent;
    border-radius: 50%;
    border-top: 14px rgb(150, 224, 108) solid;
    box-shadow: 0 0 5px 5px gray;
    animation: rotate 4s linear infinite;
} 

.logo {
    animation: zoom 4s linear infinite;
}

@keyframes zoom {
    0% {
        transform: scale(100%);
    }
    25%{
       transform: scale(115%);
    }
    50%{
       transform: scale(120%); 
    }
    75%{
        transform: scale(115%);
    }
    100% {
        transform: scale(100%);
    }
}


@keyframes rotate {
    0% {
        border-top: 14px green solid;
        box-shadow: 0px 0px 0px 1px rgb(199, 166, 74);
    }
    25%{
    border-top: 14px rgb(74, 216, 74) solid;
    }
    50%{
       box-shadow: 0px 0px 25px 25px rgb(248, 215, 67);
       border-top: 14px rgb(53, 153, 53) solid;
    }
    75%{
    border-top: 14px rgb(74, 216, 74) solid;
        
    }
    100% {
        border-top: 14px green solid;
        transform: rotate(360deg);
        box-shadow: 0px 0px 0px 1px rgb(199, 166, 74);
    }
}

</style>

<template>
  <div>
    <!-- Image and text -->
    <b-navbar toggleable="lg" variant="faded" type="light">
      <b-navbar-brand href="#">
        <img
          src="../assets/logo.png"
          class="logo d-inline-block align-top"
          alt="Kitten"
        />
        Parnika
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item to="/about">About</b-nav-item>
          <b-nav-item to="/therapies">Therapies</b-nav-item>
          <b-nav-item to="/contact" >Contact Us</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ms-auto">
          <div class="d-flex flex-nowrap">
          <b-nav-item class="p-1">
            <b-button variant="outline-primary" right @click="callTelephone">
              <b-icon icon="telephone" aria-hidden="true"></b-icon></b-button>
          </b-nav-item>
          <b-nav-item class="p-1">
            <b-button variant="outline-success" right @click="doWhatsApp">
              <b-icon icon="whatsapp" aria-hidden="true"></b-icon ></b-button>
          </b-nav-item>
          <b-nav-item class="p-1">
            <b-button variant="outline-danger" right @click="openYoutube">
              <b-icon icon="youtube" aria-hidden="true"></b-icon></b-button>
          </b-nav-item>
          <b-nav-item class="p-1">
            <b-button variant="outline-danger" right @click="sendMail">
              <b-icon icon="envelope" aria-hidden="true"></b-icon></b-button>
          </b-nav-item>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  methods: {
    callTelephone() {
      window.open("tel:+919353968267");
    },
    doWhatsApp() {
      window.open("https://wa.me/9353968267");
    },
    openYoutube(){
      window.open("https://www.youtube.com/channel/UCqS0ycSPVlvH-P4xDihLZjw");
    },
    sendMail(){
      window.open("mailto:parnikayurveda@gmail.com");
    }
  },
};
</script>

<style scoped>
.logo {
  max-height: 50px;
  max-width: 50px;
}
.navbar-brand {
  align-items: center;
  display: flex;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(4 95 63) !important;
}
</style>


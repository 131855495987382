<template>
  <div class="home mb-5">
    <b-container>

      <b-row class="mt-5">
        <b-row class="title" align-h="center">
          <b-img
            src="../assets/cover.png"
            class="titleImage img-responsive img-circle margin"
            style="display: inline"
            alt="img"
          />
        </b-row>

        <span class="text-center mt-3 mb-5">
          <h1 class="display-1">Parnika Ayurvedalaya</h1>
          <h3>A way to healthy life ...!</h3>
        </span>
      </b-row>
    
      <b-row>
        <DoctorsInfro />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DoctorsInfro from "@/components/DoctorsInfo.vue";

export default {
  name: "HomeView",
  components: {
    DoctorsInfro,
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Spirax&display=swap");
h1,
h3 {
  font-family: "Spirax", cursive;
  color: #316957f0;
}
.title {
  max-height: 80vh;
}
.titleImage {
  max-height: 80vh;
  max-width: 80vh;
  filter: drop-shadow(1px 20px 2px rgb(88, 87, 87));
}
/* .first{
  height:90vh;
} */
h4 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
</style>